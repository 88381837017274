/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate, useNavigation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "components/Input";
import { BpCheckedIcon, BpIcon } from "svg/Icons";
import {
  inputGridStyle,
  inputStyle, memberSignUpValidationSchema,
  organizationSignUpValidationSchema,
  validationSchema
} from "helper";
import CustomButton from "components/CustomButton";

const Form = ({ signin, signup, organization }) => {
  const navigate = useNavigate();

  const initialValues = signin ? {
    email: "",
    password: "",
  } : organization ? {name: "", email: "", npoName: ""} : {name: "", email: ""} ;

  const onSubmit = async (values) => {
    const body = organization ? {
      name: values.name,
      email: values.email,
      npoName: values.npoName,
    } : {
      name: values.name,
      email: values.email,
    }

    const response = await fetch("https://us-central1-direct-affect-mvp.cloudfunctions.net/sendSignUpRequestEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
      }
    )

    if (response.ok) {
      alert("Message Sent");
    } else {
      alert("Message Failed");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: signin ? validationSchema : organization ? organizationSignUpValidationSchema : memberSignUpValidationSchema,
    onSubmit,
  });

  const signClickHandler = () => {
    navigate(signin ? "/signin" : "/signup");
  };

  return (
    <Box>
      <Box
        paddingBottom={signin ? "70px" : "45px"}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontFamily: "CenturyGothic",
            fontStyle: " normal",
            fontWeight: " 600",
            fontSize: { lg: "45px", md: "48px", sm: "45px", xs: "43px" },
            lineHeight: { lg: " 35px", md: "32px", sm: "30px", xs: "35px" },

            textAlign: " center",

            color: { xs: "white", md: "#393841" },
          }}
        >
          {signin ? "Sign in" : "Sign up"}
        </Typography>
        {signup && (
          <>
            {" "}
            <Typography
              sx={{
                fontFamily: "Century-Gothic-Bold",
                fontStyle: " normal",
                fontWeight: " 500",
                fontSize: { lg: "30px", md: "28px", sm: "25px", xs: "24px" },
                lineHeight: { lg: " 30px", md: "28px", sm: "25px", xs: "24px" },
                textAlign: " center",
                marginTop: "48px",
                mb: 1,
                color: { xs: "white", sm: "white", md: "#393841" },
              }}
            >
              Get Additional Information
            </Typography>
            <Typography
              color="text.secondary"
              sx={{
                fontFamily: "Century-Gothic-Bold",
                fontStyle: " normal",
                fontWeight: " 500",
                fontSize: { lg: "18px", md: "16px", sm: "15px", xs: "15px" },
                lineHeight: { lg: "25px", md: "23px", sm: "22px", xs: "22px" },

                textAlign: " center",
                maxWidth: "670px",
                color: { xs: "white", sm: "white", md: "#52A89A" },
              }}
            >
              For access to the Direct Affect Non-Profit Community
              Engagement Platform, please complete the following form and we
              will follow up with you.
            </Typography>{" "}
          </>
        )}
      </Box>
      <form onSubmit={formik.handleSubmit} id={signin ? "signin": "signup"}>
        <Grid container spacing={4}>
          {signup && (
            <Grid item xs={12} sx={inputGridStyle}>
              <Input
                title="Name"
                name={'name'}
                value="name"
                formik={formik}
                titleStyle={{
                  ...inputStyle,
                  textAlign: "left",
                  width: "100%",
                  color: { xs: "white", sm: "white", md: "#393841" },
                }}
                inputStyle={inputStyle}
                autoFocus={true}
                placeHolder="Enter Your Full Name"
              />
            </Grid>
          )}
          <Grid item xs={12} sx={inputGridStyle}>
            <Input
              title="Email"
              value="email"
              name={'email'}
              formik={formik}
              titleStyle={{
                ...inputStyle,
                textAlign: "left",
                width: "100%",
                color: { xs: "white", sm: "white", md: "#393841" },
              }}
              inputStyle={inputStyle}
              autoFocus={!!signin}
              placeHolder="Enter Email Address"
            />
          </Grid>
          {organization && (
            <Grid item xs={12} sx={inputGridStyle}>
              <Input
                title="Non-&#8288;Profit Name"
                value="npoName"
                name={'npoName'}
                formik={formik}
                titleStyle={{
                  ...inputStyle,
                  textAlign: "left",
                  width: "100%",
                  color: { xs: "white", sm: "white", md: "#393841" },
                }}
                inputStyle={inputStyle}
                placeHolder="Enter the Name of Your Non-Profit"
              />
            </Grid>
          )}
          {signin && (
            <Grid item xs={12} sx={inputGridStyle}>
              <Box display="flex" width={1} marginBottom={1} sx={inputStyle}>
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  <Typography
                    sx={{
                      ...inputStyle,
                      color: { xs: "white", sm: "white", md: "#393841" },
                      borderRadius: "5px !important",
                    }}
                  >
                    Password*
                  </Typography>
                </Box>
              </Box>
              <TextField
                variant="outlined"
                name={"password"}
                type={"password"}
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                placeholder="****************"
                sx={{
                  ...inputStyle,
                  background: "white",
                  color: { xs: "white", sm: "white", md: "#393841" },
                  borderRadius: "5px !important",
                }}
                InputProps={{ sx: { px: "11px" } }}
                InputLabelProps={{ sx: { px: "11px" } }}
              />
            </Grid>
          )}

          {signin && (
            <Grid
              item
              xs={12}
              sx={{ ...inputGridStyle, paddingTop: "10px !important" }}
            >
              <Box
                display="flex"
                width={1}
                marginBottom={1}
                sx={{
                  ...inputStyle,
                  color: { xs: "white", sm: "white", md: "#393841" },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&:hover": { bgcolor: "transparent" },
                      }}
                      disableRipple
                      color="default"
                      checkedIcon={<BpCheckedIcon />}
                      icon={<BpIcon />}
                      inputProps={{ "aria-label": "Checkbox demo" }}
                    />
                  }
                  label="Remember me"
                  labelPlacement="end"
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                  }}
                />
              </Box>
            </Grid>
          )}

          <Grid item container xs={12} sx={inputGridStyle}>
            <CustomButton
              title={signin ? "SIGN IN" : "Submit"}
              fontFamily="Arial"
              fontStyle=" normal"
              fontWeight=" 500"
              fontSize={{ lg: "20px", md: "18px", sm: "18px", xs: "17px" }}
              lineHeight={{ lg: "24px", md: "22px", sm: "20px", xs: "17px" }}
              height="60px"
              width="100%"
              maxWidth="500px"
              color="#FFFFFF"
              textAlign=" center"
              inputType={"submit"}
              form={signin ? "signin" : "signup"}
            />

            {!organization && <><Box
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                padding: "25px",
                maxWidth: "500px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Arial",
                  fontStyle: " normal",
                  fontWeight: " 500",
                  fontSize: " 18px",
                  lineHeight: " 22px",
                  display: " flex",
                  alignItems: " center",
                  textAlign: " center",
                  textTransform: "inherit",
                  color: {xs: "white", sm: "white", md: "#393841"},
                }}
              >
                {signin ? "New to Direct Affect?" : "Already have an account?"}
              </Typography>
            </Box>
              <CustomButton
              title={
              signin ? "Sign up here for additional information " : "Sign in"
            }
              fontFamily="Arial"
              fontStyle=" normal"
              fontWeight=" 500"
              fontSize={{lg: "20px", md: "18px", sm: "18px", xs: "17px"}}
              lineHeight={{lg: "24px", md: "22px", sm: "20px", xs: "17px"}}
              onClick={() => navigate(signup ? "/signin" : "/signup")}
              height="60px"
              width="100%"
              maxWidth="500px"
              color="#1CAA9A"
              variant={"outlined"}
              background="#FFFFFF"
              border="1px solid #BBCDDC"
              textAlign=" center"
              />
            </>}
              </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
